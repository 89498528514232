<!--
 * @Descripttion:
 * @version: X1.1
 * @Author: 李波
 * @Date: 2020-06-15 18:55:53
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2021-01-06 22:27:03
-->
<template>
  <div class="content">
    <div class="top">
      <!--  搜索框  -->
      <div class="searchbox clearfix">
        <div class="searchline clearfix">
          <button class="btnicon">
            <md-icon name="search" size="lg" color="#D6D6D6"></md-icon>
          </button>
          <input
            type="text"
            placeholder="请输入搜索内容"
            v-model="searchValue"
            @keyup.13="searchContent"
            v-on:focus="getFocus"
          />
          <button class="btnclear" @click="clearinput" v-show="isclear">
            <md-icon name="fail" size="lg" color="#666"></md-icon>
          </button>
        </div>
        <div class="searchTxt" @click="searchContent">搜索</div>
      </div>
      <div class="seen">
        <div>
          <div style="display: inline-block">看 过</div>
          <span
            v-for="(item, index) in seenList"
            :key="index"
            @click="lookSearch(item)"
            >{{ item }}</span
          >
        </div>
      </div>
      <!-- <div></div> -->
    </div>
    <div class="con_centet">
      <div class="con_center-left">
        <md-scroll-view
          class="contentscroll"
          ref="scrollViewRight"
          auto-reflow
          :scrolling-x="false"
        >
          <div>
            <div class="cen_left" @click="productCategories(true)">
              <div
                v-show="isProductCategoriesTitle"
                class="cen_left-title"
              ></div>
              <div
                :class="
                  isProductCategoriesTitle ? 'cen_left-text' : 'cen_left-text2'
                "
              >
                全 部
              </div>
            </div>
            <div
              class="cen_left"
              :class="index + 1 == productClass.length ? 'cen_left-bom' : ''"
              v-for="(item, index) in productClass"
              :key="index"
              @click="productCategories(false, item)"
            >
              <div
                :class="item.ischoose ? 'cen_left-title2' : ''"
                v-if="item.ischoose"
              >
                <div class="cen_left-title"></div>
              </div>
              <div :class="item.ischoose ? 'cen_left-text' : 'cen_left-text2'">
                {{ item.typename }}
              </div>
            </div>
          </div>
        </md-scroll-view>
      </div>
      <div class="con_center-right" :class="isNull ? 'cen_right-bac' : ''">
        <div class="selectCondition">
          <div>
            <div class="top_search-left" @click="companyClcik">
              {{
                shortAgename
                  ? shortAgename.length > 4
                    ? shortAgename.substr(0, 8)
                    : shortAgename
                  : "年龄"
              }}
              <img src="@/assets/newskin/xia.png" alt />
            </div>
          </div>
          <div style="margin-left: 1rem">
            <div class="top_search-left" @click="companyClcik">
              {{
                shortname
                  ? shortname.length > 4
                    ? shortname.substr(0, 4)
                    : shortname
                  : "保险公司"
              }}
              <img src="@/assets/newskin/xia.png" alt />
            </div>
          </div>
        </div>
        <div class="mark1"></div>
        <div
          style="width: 100%; height: 100%; padding: 0 0.3rem; background: #fff"
        >
          <div v-if="noMes" class="setmid noMes">
            <img
              src="../../assets/xsqd/nomessage.png"
              alt
              style="width: 140px"
            />
          </div>
          <div class="slidBox" v-show="!noMes">
            <!-- <div class="md-example-child-scroll-view" :style="{height:`${height}px`}"> -->
            <md-scroll-view
              class="contentscroll"
              ref="scrollView"
              auto-reflow
              :scrolling-x="false"
              @end-reached="loadMorechange"
            >
              <ul class="clearfix picBox" id="content">
                <li class="boxContent" v-for="item in goodsList" :key="item.id">
                  <div class="info">
                    <img
                      v-if="item.iconhttp"
                      :src="ossurl + item.iconhttp"
                      alt="产品图"
                      @click="linkTo(item, 'comtburl')"
                    />
                    <img v-else />
                    <div class="info-con">
                      <div class="title">
                        <div
                          :class="
                            item.baseid === 'HDJJ' || item.baseid === 'HDRS'
                              ? 'nameText'
                              : 'initName'
                          "
                          @click="linkTo(item, 'comtburl')"
                        >
                          {{ item.prodname }}
                        </div>
                        <div
                          class="shareText"
                          v-if="
                            item.baseid === 'HDJJ' || item.baseid === 'HDRS'
                          "
                          @click="shareUrl(item)"
                        >
                          <i>分享</i>
                        </div>
                      </div>
                      <div
                        v-if="item.pdesc"
                        @click="linkTo(item, 'comtburl')"
                        class="descript"
                      >
                        {{ item.pdesc }}
                      </div>
                      <div
                        v-else
                        class="descript"
                        @click="linkTo(item, 'comtburl')"
                        style="height: 0.5rem"
                      ></div>
                      <div class="price">
                        ￥
                        <span @click="linkTo(item, 'comtburl')">{{
                          item.minprice ? item.minprice : 0
                        }}</span
                        >元/年起
                        <span class="productInfo" @click="productInfo(item)"
                          >产品资料</span
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <md-scroll-view-more
                slot="more"
                :is-finished="listFinished"
              ></md-scroll-view-more>
            </md-scroll-view>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="bottomframe">
        <md-popup class="inner-popup" v-model="isPopupShow" position="bottom">
          <md-popup-title-bar
            only-close
            large-radius
            :title="itemtk.prodname"
            title-align="center"
            @cancel="hidePopUp()"
          ></md-popup-title-bar>
          <div v-if="isloadingShow" class="icdoling">
            <md-icon
              name="spinner"
              color="blue"
              size="lg"
              style="-webkit-filter: invert(1)"
            ></md-icon>
          </div>
          <div class="disstable" v-if="!isloadingShow">
            <div class="asdasd" v-if="promotionFeeInfo.length == 0">
              <p class="asdasdqwe">- 暂无数据 -</p>
            </div>
            <div class="tgf_box" v-else>
              <table
                class="pftable"
                v-for="(item, index) in promotionFeeInfo"
                :key="index"
              >
                <tr v-if="index == 0">
                  <th>险种名称</th>
                  <th
                    v-for="(itemone, inde) in promotionFee"
                    :class="itemone == '1-1' ? 'firstcos' : ''"
                    :key="inde"
                  >
                    {{ inde }}
                  </th>
                </tr>
                <tr v-for="(itemtwo, ind) in item.list" :key="ind">
                  <td :rowspan="item.list.length" v-if="ind == 0">
                    {{ item.name }}
                  </td>
                  <td
                    v-for="(itemone, indexone) in promotionFee"
                    :class="itemone == '1-1' ? 'firstcos' : ''"
                    :key="indexone"
                  >
                    {{ item.list[ind][itemone] }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </md-popup>
      </div>
    </div>
    <div class="footer">
      <ul>
        <router-link tag="li" to="/main">
          <i class="hotSall heightStyle"></i>
          <span>热销</span>
        </router-link>
        <router-link tag="li" to="/Class">
          <i class="product heightStyle"></i>
          <span style="color: rgba(255, 115, 38, 1)">产品库</span>
        </router-link>
        <router-link tag="li" to="/UserInfo">
          <i class="mine heightStyle"></i>
          <span>我的</span>
        </router-link>
      </ul>
    </div>
    <!-- 年龄、保险公司筛选 -->
    <md-popup
      v-model="isSelectorShow"
      position="right"
      class="md-popupright"
      @touchmove.prevent
    >
      <div class="popupright-box">
        <div class="md-examplepopup">
          <div class="popupleft-title">投保年龄</div>
          <div style="padding-left: 0.4rem; padding-bottom: 0.5rem">
            <div class="titlecontent">
              <div v-for="(item, index) in insuranceAge" :key="index">
                <div
                  :class="!item.ischoose ? 'company-title' : 'companytitle'"
                  @click="choosetbnl(item, index)"
                >
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
          <div class="popupleft-title">保险公司</div>
          <div style="padding-left: 0.4rem; padding-bottom: 0.5rem">
            <div class="titlecontent">
              <div v-for="(item, index) in insuranceCompany" :key="index">
                <div
                  :class="!item.ischoose ? 'company-title' : 'companytitle'"
                  @click="choosefl(item, index)"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-cz">
          <div class="bottom-zj">
            <div class="bottom-chongz" @click="reset">
              <div class="chongzhi">重置</div>
            </div>
            <div
              class="bottom-qued"
              :style="`backgroundColor: ${colorprimary};`"
              @click="onSelectorChoose"
            >
              <div class="queding">确定</div>
            </div>
          </div>
        </div>
      </div>
    </md-popup>
    <div class="share" @click="cancelShare" v-show="isShareCard">
      <img src="@/assets/abt/img/fxbg.png" />
    </div>
  </div>
</template>

<script>
import {
  sxchoseList,
  productList,
  showList,
  choseList,
} from "@/api/agent/agent/index";
import { homelist, goodsClass, channelCoopCall } from "@/api/xsqd/index";
import { DoRecord, selectMJ } from "@/api/abt/customerOperation/common/index";
// import { DoRecord } from '@/api/abt/customerOperation/common/index'
import { getStorage, getUrlParameter } from "@/lib/util";
import loadMorechange from "@/mixins/loadMorechange";
import { Toast } from "mand-mobile";
import initWebSocket from "@/mixins/websock";
import config from "@/config";
import { wechatshare } from "@/lib/wechat_share";
const { redirect_uri } = config;
export default {
  mixins: [initWebSocket, loadMorechange], // 下拉加载的
  data() {
    return {
      loadflag: false,
      getBossurl: "",
      ossurl: "",
      isPopupShow: false,
      isloadingShow: false,
      promotionFeeInfo: [],
      promotionFee: [],
      isSelectorShow: false,
      searchValue: "",
      isclear: false,
      colorprimary: "",
      baseid: "",
      shortname: "",
      prodtype: "",
      typename: "",
      // getTheme1: '',
      // getTheme: '',
      isProductCategoriesTitle: true,
      listFinished: false,
      // total: 0,
      size: 10,
      listtotal: 0,
      listtotalPage: 0,
      // cplist: [],
      isNull: false,
      // tgfimg: '',
      itemtk: {},
      user: {},
      cliflag: true,
      seenList: [],
      productClass: [],
      insuranceAge: [],
      shortAgename: "",
      goodsList: [],
      noMes: false, // 默认屏幕高度是否已获取
      isFinished: false,
      height: 0,
      listpageNo: 1,
      totalPageCount: 2,
      title: "",
      typecode: "",
      insuranceCompany: [],
      insuranceid: "",
      age: "",
      isShareCard: false,
    };
  },
  beforeRouteEnter(yo, from, next) {
    next((vm) => {});
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  created() {
    this.getBossurl = getStorage("tbossurl", "");
    this.ossurl = getStorage("ossurl", "");
    // this.getTheme1 = getStorage('theme1', '')
    // this.getTheme = getStorage('theme', '')
    this.colorprimary = getStorage("theme", "");
    this.user = getStorage("u_s", {});
    this.$nextTick(() => {
      setTimeout(() => {
        this.height =
          window.innerHeight -
          document.getElementsByClassName("footer")[0].clientHeight -
          document.getElementsByClassName("top")[0].clientHeight -
          document.getElementsByClassName("mark1")[0].clientHeight +
          1;
        if (sessionStorage.getItem("productType")) {
          //  console.log(sessionStorage.getItem('productType'))
          let data = JSON.parse(sessionStorage.getItem("productType"));
          this.productCategories(false, data);
          this.productClass.forEach((item, index) => {
            if (item.typecode === data.typecode) {
              this.$set(item, "ischoose", true);
            }
          });
        }
      }, 200);
    });
    if (!sessionStorage.getItem("productType")) {
      this.getData();
    }
    this.getCompanyClass();
    // this.getData()
    // this.cplist = getStorage('productList')
    this.showKind();
    this.getAge();
    if (localStorage.getItem("seenList")) {
      this.seenList = JSON.parse(localStorage.getItem("seenList"));
    } else {
      this.seenList = [];
    }
  },
  methods: {
    // 打开保险公司选择弹窗
    companyClcik() {
      this.$nextTick(() => {
        this.isSelectorShow = true;
      });
    },
    // 获取投保年龄段
    getAge() {
      selectMJ({ list: ["TBNL"] }).then((res) => {
        this.insuranceAge = res.data.data[0].value;
        this.insuranceAge.forEach((item, index) => {
          this.$set(item, "value", item.name);
          this.$set(item, "ischoose", false);
          if (this.$route.query.shortAgename == item.name) {
            this.$set(item, "ischoose", true);
            this.shortAgename = item.name;
          } else {
            this.$set(item, "ischoose", false);
          }
        });
      });
    },
    //选择投保年龄段
    choosetbnl(item, index) {
      this.insuranceAge.forEach((item, index) => {
        item.ischoose = false;
      });
      item.ischoose = !item.ischoose;
      if (item.value === "0-17岁") {
        this.age = 1;
      } else if (item.value === "18-55岁") {
        this.age = 2;
      } else {
        this.age = 3;
      }
      this.shortAgename = item.value;
    },
    // 获取保险产品分类数据
    showKind() {
      this.typecode = "";
      let shop = {};
      goodsClass(shop).then(
        (res) => {
          if (res.data.code == 200) {
            this.productClass = res.data.data;
            this.productClass.forEach((item, index) => {
              this.$set(item, "value", item.typecode);
              this.$set(item, "text", item.typename);
              this.$set(item, "ischoose", false);
              if (this.$route.query.prodtype == item.typecode) {
                this.$set(item, "ischoose", true);
                this.prodtype = item.typecode;
                this.typename = item.typename;
              } else {
                this.$set(item, "ischoose", false);
              }
            });
          } else {
            Toast.failed("哎呀，出错了");
          }
        },
        () => {
          Toast.failed("哎呀，出错了");
        }
      );
    },
    // 获取保险公司分类数据
    getCompanyClass() {
      if (this.user.comid == "NHWX") {
        sxchoseList().then((res) => {
          this.insuranceCompany = res.data.data;
          this.insuranceCompany.forEach((item, index) => {
            this.$set(item, "value", item.baseid);
            this.$set(item, "text", item.shortname);
            if (this.$route.query.baseid == item.baseid) {
              this.$set(item, "ischoose", true);
              this.shortname = item.shortname;
            } else {
              this.$set(item, "ischoose", false);
            }
          });
        });
      } else {
        productList().then((res) => {
          this.insuranceCompany = res.data.data;
          this.insuranceCompany.forEach((item, index) => {
            this.$set(item, "value", item.baseid);
            this.$set(item, "text", item.shortname);
            if (this.$route.query.baseid == item.baseid) {
              this.$set(item, "ischoose", true);
              this.shortname = item.shortname;
            } else {
              this.$set(item, "ischoose", false);
            }
          });
        });
      }
    },
    // 选择保险公司分类
    choosefl(item, index) {
      this.insuranceCompany.forEach((item, index) => {
        item.ischoose = false;
      });
      item.ischoose = !item.ischoose;
      this.baseid = item.value;
      this.shortname = item.text;
    },
    // 重置保险公司选择
    reset() {
      this.insuranceCompany.forEach((item, index) => {
        item.ischoose = false;
      });
      this.insuranceAge.forEach((item, index) => {
        item.ischoose = false;
      });
      this.baseid = "";
      this.shortname = "";
      this.shortAgename = "";
      this.age = "";
    },
    // 选择产品分类
    productCategories(type, val) {
      this.productClass.forEach((item, index) => {
        item.ischoose = false;
      });
      if (type) {
        this.isProductCategoriesTitle = true;
        this.prodtype = "";
        sessionStorage.setItem("productType", "");
      } else {
        this.isProductCategoriesTitle = false;
        this.prodtype = val.typecode;
        val.ischoose = !val.ischoose;
        sessionStorage.setItem("productType", JSON.stringify(val));
      }

      this.listpageNo = 1;
      this.goodsList = [];
      this.getData();
    },
    // 选择保险公司
    onSelectorChoose() {
      this.goodsList = [];
      this.isSelectorShow = false;
      this.listpageNo = 1;
      this.getData();
    },
    // 点击搜索
    searchContent() {
      this.goodsList = [];
      this.listpageNo = 1;
      if (this.searchValue) {
        if (this.seenList.length > 4) {
          this.seenList = this.seenList.splice(1, 4);
          this.$nextTick(() => {
            if (this.seenList.indexOf(this.searchValue) === -1) {
              this.seenList.push(this.searchValue);
            }
            localStorage.setItem("seenList", JSON.stringify(this.seenList));
          });
        } else {
          this.$nextTick(() => {
            if (this.seenList.indexOf(this.searchValue) === -1) {
              this.seenList.push(this.searchValue);
            }
            localStorage.setItem("seenList", JSON.stringify(this.seenList));
          });
        }
      }
      this.getData();
      this.$forceUpdate();
    },
    // 搜索框失焦事件
    getFocus() {
      this.isclear = true;
    },
    // 清空搜索框
    clearinput() {
      this.searchValue = "";
      this.isclear = false;
    },
    //隐藏弹窗
    hidePopUp() {
      this.isPopupShow = false;
    },
    //获取产品列表
    getData() {
      let age;
      if (this.age) {
        age = this.age;
      } else {
        age = null;
      }
      let data = {
        page: this.listpageNo,
        size: 10,
        salecomid: this.user.salecomid,
        prodname: this.searchValue,
        prodtype: this.prodtype,
        insuranceid: this.baseid,
        age: age,
        isHome: "0",
      };
      homelist(data)
        .then((res) => {
          // let rows = res.data.data.rows;
          // let tatal = res.data.data.total;
          // this.totalPageCount = Math.ceil(tatal / 10);
          // if (rows.length != 0) {
          //   for (let i = 0; i < rows.length; i++) {
          //     this.goodsList.push(rows[i]);
          //   }
          //   this.noMes = false;
          //   this.$forceUpdate();
          // } else {
          //   this.noMes = true;
          // }
          this.goodsList =
            this.goodsList.length == "0"
              ? res.data.data.rows
              : data.page == 1
              ? res.data.data.rows
              : this.goodsList.concat(res.data.data.rows);
          if (this.goodsList.length == 0) {
            this.noMes = true;
          } else {
            this.noMes = false;
          }
          this.listtotal = res.data.data.total;
          this.listtotalPage = res.data.data.totalpage;
          if (this.listtotalPage <= this.listpageNo) {
            this.listFinished = true;
          } else {
            this.listFinished = false;
            this.$refs.scrollView.finishLoadMore();
          }
          this.loadflag = true;
        })
        .finally(() => {
          Toast.hide();
          this.isSelectorShow = false;
          // this.isChoseShow = false
        });
    },
    //看过
    lookSearch(val) {
      this.searchValue = val;
      this.listpageNo = 1;
      this.goodsList = [];
      this.getData();
    },
    //跳转到产品资料页面
    productInfo(item) {
      this.$router.push(`/qdinformation?prodcode=${item.prodcode}`);
    },
    //保险产品跳转
    linkTo(val, value) {
      if (!this.cliflag) {
        return;
      }
      this.cliflag = false;
      // if(val.baseid == 'HDJJ') {
      //   this.getHdUrl(val)
      //   return
      // }
      this.$store
        .dispatch("SALES_PERMISSION", {
          url: val.comtburl,
          baseid: val.baseid,
        })
        .then((response) => {
          if (response.urltype == "W" && val.baseid == "YSYF") {
            val.tburl = `${val.comtburl.split("?")[0]}?userTrack=${
              response.userTrack
            }`;
          } else if (response.urltype == "W" && val.baseid == "ZAZX") {
            val.tburl = `${val.comtburl.split("param=")[0]}&bizContent=${
              response.bizContent
            }`;
          } else if (val.baseid == "ZXJJ") {
            if (val.comtburl.includes("userTrack") > -1) {
              val.tburl = val.comtburl.replace("userTrack", response.agent);
            } else {
              val.tburl = `${val.comtburl}&agent=${response.agent}`;
            }
          } else if (response.urltype == "W" || val.baseid == "TPRS") {
            val.tburl = val.comtburl.includes("?")
              ? val.comtburl
              : `${val.comtburl}?comid=${val.comid}&prodcode=${response.prodcode}&empno=${response.empno}`;
          } else if (response.urltype == "W") {
            val.tburl = val.comtburl.includes("?")
              ? `${val.comtburl}&comid=${response.comid}&prodcode=${response.prodcode}&empno=${response.empno}`
              : `${val.comtburl}? comid=${response.comid}&prodcode=${response.prodcode}&empno=${response.empno}`;
          } else {
            val.tburl = val.comtburl;
          }
          if (val.jumpmode === "2") {
            let channelToken = getUrlParameter(val.comtburl, "channelToken");
            let channelProd = getUrlParameter(val.comtburl, "channelProd");

            let params = {
              bizType: 1,
              data: {
                channelProd: channelProd,
                channelToken: channelToken,
              },
              channel: val.baseid,
              operType: 1,
            };
            channelCoopCall(params)
              .then((res) => {
                Toast.hide();
                if (res.data.code === 200) {
                  if (res.data.data) {
                    window.location.href = res.data.data;
                  }
                }
              })
              .catch((err) => {
                Toast.hide();
              });
          } else {
            window.location.href = `${val.tburl}&source=qd`;
          }
        })
        .finally(() => {
          this.cliflag = true;
        });
      // window.location.href = item[value]
    },
    // getHdUrl (item) {
    //   const obj = {
    //     baseid: item.baseid,
    //     url: item.comtburl
    //   }
    //   getInsureUrl(obj).then(response => {
    //     window.location.href = `${response.data.data}&source=qd`
    //   }).finally((res) => {
    //     this.cliflag = true
    //   })
    // },
    //分享产品
    shareUrl(item) {
      this.isShareCard = true;
      wechatshare(
        item.prodname + "产品分享",
        item.comtburl,
        this.ossurl + item.iconhttp,
        encodeURI(
          redirect_uri + "/shareLinkPage?shareParamData=" + item.comtburl
        ),
        "",
        "no"
      );
    },
    cancelShare() {
      this.isShareCard = false;
    },
  },
};
</script>

<style scoped lang="stylus" >
.content {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #FAFAFA;
}

.top {
  height: 1.79rem;
  width: 100vw;
  // display: flex;
  // background-color: #FAFAFA;
  background: #FFFFFF;
}

.top_search {
  display: flex;
}

.top_search-left {
  display: flex;
  height: 100%;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  font-size: 0.32rem;
  font-weight: 400;
  font-family: Microsoft YaHei;
  color: rgba(102, 102, 102, 1);
}

.top_search-right {
  display: flex;
  width: 0.5rem;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.top_search-left img {
  margin-left: 0.2rem;
  width: 0.15rem;
  height: 0.15rem;
}

.seen {
  color: #535353;
  margin-left: 0.32rem;

  div {
    font-size: 0.3rem;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    width: 1.68rem;
    height: 0.46rem;
    line-height: 0.46rem;
    border: 1px solid #FFBE9B;
    font-size: 0.3rem;
    border-radius: 0.225rem;
    color: #FF7326;
    text-align: center;
    margin-left: 0.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}

.searchbox {
  display: flex;
  height: 60%;
  // width: 7.3rem;
  align-items: center;
  margin-left: 0.32rem;

  .searchline {
    height: 0.85rem;
    width: 8.32rem;
    border-radius: 0.35rem;
    border: 0.015rem solid #BEBEBE;
  }

  .btnicon {
    width: 12%;
    height: 0.85rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background: none;
  }

  input {
    height: 0.85rem;
    width: 65%;
    border: none;
    background: none;
    font-size: 0.32rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    // color: #A5A5A5;
    color: #333333;
    position: absolute;
  }

  input::placeholder {
    color: #B1B1B1;
  }

  .btnclear {
    background: none;
    position: absolute;
    right: 1.6rem;
    line-height: 0.85rem;
  }

  .searchTxt {
    font-size: 0.35rem;
    color: #474747;
    margin-left: 0.25rem;
  }
}

.con_centet {
  display: flex;
  box-shadow: 0rem 0rem 1rem 0rem rgba(139, 139, 139, 0.15);
  background: #FFFFFF;

  .con_center-left {
    height: 88vh;
    background-color: #f2f2f2;

    .cen_left-bom {
      margin-bottom: 1.5rem;
    }

    .cen_left {
      display: flex;
      align-items: center;
      width: 1.88rem;
      height: 1.2rem;

      .cen_left-title {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 0.08rem;
        height: 0.48rem;
        background-color: #FF8A4D;
        margin-left: 0.05rem;
      }

      .cen_left-title2 {
        // background-color: #FAFAFA;
        background: #F7F7F7;
        // height 100%
      }

      .cen_left-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 0.36rem;
        color: #FF8A4D;
        font-weight: bold;
        font-family: Microsoft YaHei;
        background-color: #ffffff;
        // background-color:#F7F7F7;
      }

      .cen_left-text2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 0.36rem;
        color: #525252;
        // background-color: #F2F2F2;
        background-color: #F7F7F7;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }
  }

  .cen_right-bac {
    background-color: #ffffff;
  }

  .con_center-right {
    // display: flex;
    height: 88vh;
    width: 100%;
    // align-items: center;
    background: #FFFFFF;
    margin-left: 0.05rem;
    box-shadow: 0rem 0rem 1rem 0rem rgba(139, 139, 139, 0.15);

    .selectCondition {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0.86rem;
      border-bottom: 1px solid #e9e9e9;
    }

    // margin-bottom 2rem
    .asdasdqwe {
      text-align: center;
      color: #c4c4c4;
    }

    /deep/ .scroll-view-container {
      padding-bottom: 3rem;
    }

    .md-scroll-view-more {
      position: fixed;
      // bottom: -4rem;
      left: 35%;
    }

    .cen_right-bom {
      margin-bottom: 1.5rem;
    }

    .cen_right {
      width: 7.3rem;
      height: 4.2rem;
      border: 1px solid #F7F7F7;
      margin-top: 0.3rem;
      background-color: #ffffff;
      border-radius: 0.2rem;
      // box-shadow: 0 0 0.13333rem 0.13333rem rgba(49,47,41,.04);
      box-shadow: 0 0.15rem 0.05rem -0.05rem #EBEBEB;

      .cen_right-top {
        height: 75%;
        border-bottom: 1px solid #F7F7F7;

        .top-title {
          font-size: 0.42rem;
          margin-left: 0.3rem;
          font-weight: 400;
          height: 1rem;
          line-height: 1rem;
          padding-top: 0.1rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .product-features {
          display: flex;
          border-top: 1px #f7f7f7 solid;
          padding-left: 0.3rem;
          justify-content: flex-start;
          height: 0.8rem;
          align-items: center;

          div {
            padding: 0.02rem 0.15rem;
            font-size: 0.27rem;
            line-height: 0.44rem;
            text-align: center;
            color: #FF5A36;
            margin-right: 0.2rem;
            border-radius: 6px;
          }
        }

        .product-commission {
          float: right;
          width: 1.2rem;
          font-size: 0.27rem;
          height: 0.44rem;
          line-height: 0.44rem;
          margin-right: 0.4rem;
          margin-top: -0.62rem;
          color: #FF5A36;
          border-radius: 6px;
          text-align: center;
        }

        .product-introduce {
          width: 90%;
          margin-left: 0.3rem;
          margin-top: 0.15rem;
          font-size: 0.29rem;
          color: #9A9A9A;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .cen_right-bottom {
        height: 25%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px 0.4rem;

        div {
          font-size: 0.34rem;
        }

        .cen_qd {
          display: flex;
          width: 2.2rem;
          height: 0.6rem;
          // color #FF5A36
          background: #FFF5EC;
          justify-content: center;
          align-items: center;
          border-radius: 0.28rem;
          // border 1px solid #FFF5EC
          background-image: linear-gradient(to top, #FFCEC3, #FFEFE6);
        }

        .right_bom-cen {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 4rem;

          .cen-img {
            max-width: 0.8rem;
            max-height: 0.8rem;
            line-height: 0.8rem;
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
            margin-right: 0.2rem;
            margin-top: 0.05rem;
          }

          .cen_company {
            color: #666666;
            font-size: 0.3rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.md-popupright /deep/.md-slide-left {
  width: 82.6%;
  height: 100%;
  background-color: #ffffff;
}

.popupright-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.md-examplepopup {
  width: 100%;
  flex: 1;
  overflow-x: auto;
}

.popupleft-title {
  margin-bottom: 0.61rem;
  font-size: 0.46rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: #3d3d3d;
  margin-top: 0.97rem;
  margin-left: 0.4rem;
}

.titlecontent {
  display: flex;
  flex-wrap: wrap;
}

.company-title {
  padding: 0.2rem 0.45rem;
  background-color: #f5f5f5;
  margin-right: 0.3rem;
  margin-bottom: 0.25rem;
  font-size: 0.33rem;
  border-radius: 0.1rem;
  color: #707070;
}

.companytitle {
  padding: 0.2rem 0.45rem;
  background-color: #fb6822;
  margin-right: 0.3rem;
  margin-bottom: 0.25rem;
  font-size: 0.33rem;
  border-radius: 0.1rem;
  color: #fff;
}

.bottom-cz {
  height: 1.23rem;
  display: flex;
  border-top: 0.01rem solid #f5f5f5;
}

.bottom-zj {
  display: flex;
  justify-content: center;
  height: 1.23rem;
  width: 100%;
}

.bottom-chongz {
  width: 50%;
  display: flex;
  align-items: center;
  backgound-color: #fff;
}

.bottom-qued {
  width: 50%;
  display: flex;
  align-items: center;
}

.chongzhi {
  margin: auto;
  color: #383838;
  font-family: PingFang-SC-Medium;
  font-size: 0.43rem;
}

.queding {
  margin: auto;
  color: #ffffff;
  font-family: PingFang-SC-Medium;
  font-size: 0.43rem;
}

.contentscroll {
  calc(100vh - 2rem);
}

.bottomframe {
  z-index: 999;
}

.bottomframe /deep/ .md-slide-up {
  height: 60vh;
}

.inner-popup/deep/.title {
  font-weight: 600 !important;
  font-size: 0.5rem !important;
  color: #383838 !important;
}

.inner-popup/deep/.large-radius {
  position: fixed !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 1.5rem;
}

.inner-popup/deep/.title-bar-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inner-popup/deep/ .md-icon {
  font-size: 0.8rem;
}

.disstable {
  padding: 1.9rem 0.5rem 0.8rem;
}

.asdasd {
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
  margin-left: 3.4rem;

  .asdasdqwe {
    color: #999999;
  }
}

.tgf_box {
  width: 9rem;
  overflow: auto;
  margin: 0 auto;
}

.pftable {
  width: 100%;
  margin: 0 auto;
  table-layout: fixed;
}

.pftable th {
  border: 1px solid #fff;
  background-color: #f2f2f2;
  font-size: 28px;
  height: 0.8rem;
  padding: 0.1rem;
  font-weight: bold;
  text-align: center;
}

.firstcos {
  background-color: #fce9db !important;
}

.pftable td {
  border: 1px solid #fff;
  background-color: #f2f2f2;
  font-size: 28px;
  height: 0.8rem;
  padding: 0.1rem;
  text-align: center;
}

/deep/ .icdoling {
  width: 60vh;
  text-align: center;
  margin-top: 4rem;
}

/deep/ .icdoling .md-icon {
  width: 1.42rem;
  height: 1.42rem;
  line-height: 1.42rem;
}

.footer {
  background-color: #fff;
  width: 100%;
  height: 1.4rem;
  border-top: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  z-index: 9;
}

.footer>ul {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer ul li {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footer ul li span {
  font-size: 0.35rem;
  color: #6b6b6b;
}

.footer ul li i {
  font-size: 1rem;
  color: #6b6b6b;
}

.heightStyle {
  width: 0.72rem;
  height: 0.72rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hotSall {
  background-image: url('~@/assets/image/home_unselected.png');
}

.product {
  background-image: url('~@/assets/image/product_selected.png');
}

.mine {
  background-image: url('~@/assets/image/my_unselected.png');
}

.noMes {
  padding-top: 35%;
  text-align: center;
}

.slidBox {
  display: flex;
  width: 100%;
  height: 88vh;
  // margin-bottom: 2rem;
  background: white;

  .md-example-child-scroll-view {
    height: 13.4rem;
  }
}

.content {
  background: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
}

.picBox {
  width: 100%;
  background: #ffffff;
  padding-right: 0;
  padding-bottom: 0;
}

.info {
  width: 100%;
  display: flex;
  height: 2.5rem;

  img {
    width: 2.135rem;
    height: 2.135rem;
    // width:34%;
    margin-right: 0.15rem;
    background: rgba(248, 228, 185, 1);
    position: relative;
    border-radius: 0.16rem;
  }

  .info-con {
    display: flex;
    width: 65%;
    flex-direction: column;
    // justify-content: space-between;
    text-align: left;

    .title {
      display: flex;

      .initName {
        font-size: 0.35rem;
        font-weight: bold;
        color: rgba(55, 55, 55, 1);
        text-overflow: ellipsis;
        // white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 块元素显示的文本行数
        -webkit-box-orient: vertical;
      }

      .nameText {
        flex: 0 0 75%;
        font-size: 0.35rem;
        font-weight: bold;
        color: #373737;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .shareText {
        flex: 0 0 25%;
        text-align: right;

        i {
          display: inline-block;
          border: 1px solid #ffd9b9;
          border-radius: 0.25rem;
          padding: 0.05rem 0.2rem;
          background-image: linear-gradient(to bottom, #fff5ec, #ffdfd1);
          color: rgb(236, 45, 56);
          font-weight: 400;
          font-size: 0.33rem;
        }
      }
    }

    .descript {
      color: rgba(120, 120, 120, 1);
      font-size: 0.29rem;
      max-width: 6.4rem;
      overflow: hidden;
      padding: 1px 0 4px 0;
      text-overflow: ellipsis;
      // white-space: nowrap;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 块元素显示的文本行数
      -webkit-box-orient: vertical;
    }

    .price {
      color: rgba(255, 115, 38, 1);
      font-size: 0.3rem;
      margin-top: 5px;
      font-weight: bold;

      span {
        font-size: 0.503rem;
      }

      .productInfo {
        display: inline-block;
        width: 1.36rem;
        height: 0.48rem;
        line-height: 0.48rem;
        background: #FF7326;
        border-radius: 0.08rem;
        font-size: 0.26rem;
        color: #ffffff;
        text-align: center;
        float: right;
        margin-top: 0.05rem;
      }
    }
  }
}

// .info{
// width: 100%;
// display: flex;
// height: 2.5rem;
// }
// .info img{
// width: 2rem;
// height: 2rem;
// margin: 0 0.5rem;
// }
// .info .info-con{
// display: flex;
// flex-direction: column;
// justify-content: space-between;
// text-align: left;
// }
// .info-con .title{
// font-size: .4rem;
// font-weight: bold;
// color: #515151
// }
// .info-con .descript{
// color: #6b6b6b;
// font-size: .35rem;
// max-width: 6rem;
// overflow: hidden;
// text-overflow:ellipsis;
// white-space: nowrap;
// }
// .info-con .price{
// color: #ff0300;
// font-size: .4rem;
// font-weight: bold;
// }
.boxContent {
  width: 100%;
  padding: 0.3rem 0;
  background: #ffffff;
  margin-right: 0.5rem;
  // border-bottom:1px solid #e4e2e2;
  display: flex;
  justify-content: space-between;
}

.share {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0;
  z-index: 999;
  position: fixed;
  top: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
  }
}
</style>
