/*
 * @Author: 王鹏
 * @Date: 2020-03-06 16:52:37
 * @LastEditors: 王鹏
 * @LastEditTime: 2020-03-18 15:23:12
 */
import axios from '@/lib/api.request'

// 渠道用户查询
export const getInfo = data => axios.post('/abt/abtComQdProd/myPage', data)

// 热销产品列表
export const homelist = data => axios.post('/abt/abtComQdProd/homeList', data)

// 产品分类
export const goodsClass = params => axios.get('/hx/abtComHxClassTypedefine/choseList', params)

// 解除绑定
export const xsqdUnbind = data => axios.post('/abt/abtComQdSaleempno/unbind', data)

// 获取订单
export const getMyPageOrder = data =>{
  console.log(data);
  const orderApiList = {
    0: '/abt/abtComQdProd/myPage/order/notpay',
    1: '/abt/abtComQdProd/myPage/order/valid',
    2: '/abt/abtComQdProd/myPage/order/refund',
    3: '/abt/abtComQdProd/myPage/order/all'
  }
  return axios.post(orderApiList[data.ddstatus], data)
}

//获取批单列表查询
export const getCoopOrderlist = data => axios.post('/hx/channel/coop/orderList',data)

//查看财险订单详情
export const getCoopOrderDetail = data => axios.post('/hx/channel/coop/orderDetail', data)

//调用子系统
export const channelCoopCall = data => axios.post('/hx/channel/coop/call', data)

//退保、核保、发票数据接口
export const coopMyPage = data => axios.post('/hx/channel/coop/myPage', data)


// //get 链接
// export const getNewUrl = (url, params)=> axios.get(url, params)
//跳转页面
export const interInsurance = data => axios.post('/ext/interface/insurance', data)